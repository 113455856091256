<template>
	<div class="bg-HeaderBg w-full relative" style="height: 60vh">
		<img class=" absolute -left-0 top-0 z-20 h-1/2" src="../assets/Pattren.svg" alt="" />
		<img class=" absolute -right-0 bottom-0 z-20 h-1/2" src="../assets/Pattren.svg" alt="" />
		<div class="relative z-40 container mx-auto px-5 md:px-8 flex items-center justify-center h-full">
			<div>
				<h1 class="text-black font-bold tracking-wide leading-relaxed text-3xl lg:text-4xl mb-3">{{ title }}</h1>
				<p class="text-Gray font-medium text-md md:text-lg">
					<button class="outlne-none focus:outline-none" @click="$router.push('/')">الرئيسية</button>
					/
					<span> {{ link }}</span>
				</p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Header',
	props: ['title', 'link'],
};
</script>
